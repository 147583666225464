
import { PropType, defineComponent, getCurrentInstance, ref, watch } from 'vue'
import { Theme } from '~/types/theme'

export default defineComponent({
  name: 'FaqItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    theme: {
      type: String as PropType<Theme>,
      default: 'light',
    },
  },

  setup (props) {
    const app = getCurrentInstance()
    const isExpanded = ref(false)

    const toggle = () => {
      isExpanded.value = !isExpanded.value
    }

    watch(() => isExpanded, (state) => {
      if (!state) {
        return
      }

      app?.proxy.$analytics.GTM.trackRaw({
        eventCategory: 'FAQs',
        eventAction: 'click',
        eventLabel: props.title,
      })
    })

    return {
      isExpanded,
      toggle,
    }
  },
})
