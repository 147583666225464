
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { Provider } from '~/core/offer.types'
import RatingStars from '~/components/sections/rating/RatingStars.vue'
import RatingBar from '~/components/sections/rating/RatingBar.vue'
import RatingServices from '~/components/sections/rating/RatingServices.vue'
import { AlButton } from '~/components/ui'

@Component({
  components: {
    AlButton,
    RatingStars,
    RatingBar,
    RatingServices,
  },
})
export default class RatingCard extends Vue {
  @Prop({ required: true }) readonly provider!: Provider
  @Prop({ required: false, default: false }) readonly isShowCTA!: boolean
  @Prop({ required: true, default: 0 }) readonly slideIndex!: number
  @Prop({ required: false, default: '', type: String })
  private readonly ctaText!: string

  get providerScore () {
    return this.provider.avg_score || 0
  }

  @Emit('on-cta-click')
  onCTAClick () {
    return this.slideIndex
  }
}
