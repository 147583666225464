
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { OfferCategoryType, OfferProvider } from '@alao-frontend/core'

import IconAssistantLogo from '~/assets/icons/icon-assistant-logo.svg?inline'
import { AlButton } from '~/components/ui'

@Component({
  components: {
    AlButton,
    IconAssistantLogo,
  },
})
export default class OfferCardLayout extends Vue {
  @Prop({ required: true, type: String })
  readonly offerId!: string

  @Prop({ required: true, type: String })
  readonly offerType!: OfferCategoryType

  @Prop({ required: false, default: '', type: String })
  readonly title!: string

  @Prop({ required: true, type: Array })
  readonly providers!: OfferProvider[]

  @Prop({ required: true, default: '', type: String })
  readonly slug!: string

  @Prop({ required: false, type: Boolean, default: false })
  readonly showAssistantToggle!: boolean

  @Prop({ required: false, default: '', type: String })
  readonly borderColor!: string

  @Prop({ required: true, default: '', type: String })
  readonly url!: string

  @Prop({ required: false, type: Boolean, default: true })
  clickable!: boolean

  get layoutStyles () {
    return {
      borderColor: this.borderColor ? this.borderColor : 'var(--border-color)',
    }
  }

  openAssistant () {
    this.$assistant
      .initialize({
        offerId: this.offerId,
        offerTitle: this.title,
        offerType: this.offerType,
        offerSlug: this.slug,
        providers: this.providers,
      })
      .open()
  }

  @Emit('click')
  onClick () {
    return true
  }
}
