
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { PriceExplanationBenefit } from '@alao-frontend/core'
import OfferCardDiscountLabel from '~/components/offer-card/OfferCardDiscountLabel.vue'
import OfferCardExclusiveLabel from '~/components/offer-card/OfferCardExclusiveLabel.vue'
import PriceExplanation from '~/components/offers/PriceExplanation.vue'
import IconInfo from '~/assets/icons/icon-info.svg?inline'
import { FeatureFlags } from '~/core/config/feature-flags'
import AppTooltip, { TooltipPositions, TooltipTrigger } from '~/components/AppTooltip.vue'

@Component({
  components: {
    OfferCardDiscountLabel,
    OfferCardExclusiveLabel,
    PriceExplanation,
    IconInfo,
    AppTooltip,
  },
})
export default class OfferCardPrice extends Vue {
  @Prop({ type: Number, default: 0 })
  readonly regularPrice!: number

  @Prop({ required: true, default: 0, type: Number })
  readonly finalPrice!: number

  @Prop({ required: false, default: '', type: String })
  readonly priceDiscountLabel!: string

  @Prop({ required: true, default: 0, type: Number })
  readonly effectivePercent!: number

  @Prop({ required: true, default: 0, type: Number })
  readonly effectivePrice!: number

  @Prop({ required: true, default: 0, type: Number })
  readonly discountDaysLeft!: number

  @Prop({ required: true, default: 0, type: Number })
  readonly contractDuration!: number

  @Prop({ required: true, default: 0, type: Number })
  readonly savings!: number

  @Prop({ required: true, default: () => ([]), type: Array })
  readonly benefits!: PriceExplanationBenefit[]

  @Prop({ default: 0, type: Number })
  readonly progressBar!: number | null

  @Prop({ default: null, type: String })
  readonly endDateInFewDays!: string | null

  @Prop({ default: false, type: Boolean })
  readonly isAlaoExclusiveOffer!: boolean

  @Prop({
    default: 'hover',
    type: String,
  })
  readonly pricesTooltipTrigger!: TooltipTrigger

  @Prop({
    default: true,
    type: Boolean,
  })
  readonly showDiscountLabel!: boolean

  @Prop({
    default: true,
    type: Boolean,
  })
  readonly priceExplanation!: boolean

  isPriceExplanationVisible = false

  toggleIsPriceExplanationVisible (force?: boolean) {
    this.isPriceExplanationVisible = force ?? !this.isPriceExplanationVisible
  }

  handlePriceClick () {
    if (!this.$device.isTouch) {
      return
    }

    this.toggleIsPriceExplanationVisible()
  }

  get isShowPriceExplanation () {
    return !!this.benefits.length && this.priceExplanation
  }
}
