
import VueSlickCarousel from 'vue-slick-carousel'
import { Component, Vue, namespace, Prop, Emit } from 'nuxt-property-decorator'
import { namespace as offerStore, OfferState } from '~/store/modules/Offer'
import RatingCard from '~/components/sections/rating/RatingCard.vue'
import { OnMounted } from '~/core/vue.types'
import { AlButton } from '~/components/ui'

const OfferStore = namespace(offerStore)

@Component({
  components: {
    VueSlickCarousel,
    RatingCard,
    AlButton
  },
})
export default class RatingSection extends Vue implements OnMounted {
  @Prop({ required: false, default: false }) readonly isWithForm!: boolean
  @Prop({ required: false, default: false }) readonly isWithCta!: boolean
  @Prop({ required: false, default: -1 }) readonly selectedProvider!: number
  @OfferStore.State('providersList') providersList!: OfferState['providersList']

  private settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: '0px',
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1339,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
          dots: false,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          dots: false,
          initialSlide: 0,
        },
      },
    ],
  }

  @Emit()
  onSlideChange (slideIdx: number) {
    return slideIdx
  }

  @Emit('on-add-review-click')
  onCTAClick (slideIdx: number) {
    return slideIdx
  }

  setSlideTo (index: number) {
    // TODO: Typing for goTo()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.ratingsCarousel.goTo(index)
  }

  mounted () {
    if (this.selectedProvider === -1) { return }
    const index = this.providersList.map(p => p.pk).indexOf(this.selectedProvider)

    if (index === -1) { return }

    this.$nextTick(() => {
      this.setSlideTo(index)
      this.onSlideChange(index)
    })
  }
}
