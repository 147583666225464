
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import type { HomeOffer, MobileOffer, BundleOffer, DeviceOffer } from '@alao-frontend/core'
import { NamedOfferCategoryType, OfferCategoryType } from '@alao-frontend/core'
import BundleOfferCard from '~/components/offers/OfferCard/BundleOfferCard'
import HomeOfferCard from '~/components/offers/OfferCard/HomeOfferCard'
import MobileOfferCard from '~/components/offers/OfferCard/MobileOfferCard'
import PromoOfferCard from '~/components/offers/OfferCard/Promo/PromoOfferCard.vue'
import type { AdditionalQueryParams } from '~/core/osp/additional-query-params'
import { plainObjectToRouteQueryObject } from '~/core/osp/additional-query-params'
import type { TooltipTrigger } from '~/components/AppTooltip.vue'

@Component
export default class OfferCard extends Vue {
  @Prop({ required: true, type: Object })
  readonly offer?: HomeOffer | MobileOffer | BundleOffer | DeviceOffer

  @Prop({ required: false, type: Object, default: () => ({}) })
  readonly additionalParams!: AdditionalQueryParams

  @Prop({ required: false, type: Boolean, default: true })
  readonly clickable!: boolean

  @Prop({ required: false, type: Boolean, default: false })
  readonly showNewMobileCardsInfo!: boolean

  @Prop({
    default: 'hover',
    type: String,
  })
  readonly pricesTooltipTrigger!: TooltipTrigger

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly promo!: boolean

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly topDeal!: boolean

  get component () {
    if (!this.offer) {
      return 'span'
    }

    const constructor = this.offer.constructor as
      | typeof HomeOffer
      | typeof MobileOffer
      | typeof BundleOffer
      | typeof DeviceOffer
    switch (constructor.offerType) {
      case OfferCategoryType.MOBILE_ABO_OFFER:
        return this.promo ? PromoOfferCard : MobileOfferCard

      case OfferCategoryType.HOME_OFFER:
        return this.promo ? PromoOfferCard : HomeOfferCard

      case OfferCategoryType.BUNDLE_OFFER:
        return BundleOfferCard

      case OfferCategoryType.DEVICE_WITH_MOBILE_PLAN_OFFER:
        throw new Error('Not implemented yet')

      default:
        throw new Error('Unreachable offer type')
    }
  }

  get url () {
    if (!this.offer) {
      return ''
    }

    const namedType = NamedOfferCategoryType[this.offer.type]
    const pathName = `compare-${namedType}-slug`

    const additionalParams = plainObjectToRouteQueryObject(this.additionalParams)

    return this.localePath({
      name: pathName,
      params: {
        slug: this.offer.slug,
      },
      query: {
        ...additionalParams,
      },
    })
  }

  @Emit('click')
  onClick () {
    return true
  }
}
