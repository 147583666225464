
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import IconAssistantLogo from '~/assets/icons/icon-assistant-logo.svg?inline'
import { AlButton } from '~/components/ui'

@Component({
  components: {
    AlButton,
    IconAssistantLogo,
  },
})
export default class PromoOfferCardLayout extends Vue {
  @Prop({ required: true, default: '', type: String })
  readonly url!: string

  @Prop({ required: false, type: Boolean, default: true })
  clickable!: boolean

  @Prop({ required: false, type: Boolean, default: false })
  topDeal!: boolean

  @Emit('click')
  onClick () {
    return true
  }
}
