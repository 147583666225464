
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { OptionCategory } from '@alao-frontend/core'
import OfferOptionsItemIcon from '~/components/offers/OfferOptions/OfferOptionsItemIcon.vue'

import IconInfo from '~/components/icons/IconInfo.vue'
import BenefitTooltip from '~/components/BenefitTooltip.vue'

@Component({
  components: { BenefitTooltip, IconInfo, OfferOptionsItemIcon },
})
export default class OfferMainDetailsItem extends Vue {
  @Prop({ required: true, type: String })
  readonly category!: OptionCategory

  @Prop({ required: true, default: '', type: String })
  readonly title!: string

  @Prop({ required: false, default: '', type: String })
  readonly content?: string

  @Prop({ required: false, default: '', type: String })
  readonly tooltipContent?: string

  @Prop({ required: false, default: '#EE7F1A', type: String })
  readonly iconColor?: string
}
