
import { Component, Prop } from 'nuxt-property-decorator'
import {
  createAmpTranslatedOfferDetailsInstance,
  createTranslatedOfferBenefitsInstance,
  MobileOffer,
  TranslatableContext,
} from '@alao-frontend/core'
import OfferMainDetails from './OfferMainDetails.vue'
import PromoOfferCardDiscountLabel from './PromoOfferCardDiscountLabel.vue'
import PromoOfferCardLayout from '~/components/offers/OfferCard/Promo/PromoOfferCardLayout.vue'
import ExpertTip from '~/components/offers/OfferCard/ExpertTip'
import OfferCardDetails from '~/components/offers/OfferCard/OfferCardDetails'
import OfferPromoBanners from '~/components/offers/OfferPromoBanners'
import OfferDynamicBanner from '~/components/banners/OfferDynamicBanner'
import OfferCardPrice from '~/components/offers/OfferCard/OfferCardPrice.vue'
import { OfferCardMixin } from '~/components/offers/OfferCard/mixins/offer-card.mixin'
import { checkDateLessDays } from '~/utils/check-date-less-days'
import type { TooltipTrigger } from '~/components/AppTooltip.vue'
import OfferCardExclusiveLabel from '~/components/offer-card/OfferCardExclusiveLabel.vue'
import OfferCardDetailsContent from '~/components/offers/OfferCard/OfferCardDetails/OfferCardDetailsContent.vue'
import OfferBenefitBadge from '~/components/offers/benefits/OfferBenefitBadge.vue'
import OfferOptions from '~/components/offers/OfferOptions'
import { AlButton } from '~/components/ui'
import OfferMainDetailsItem from '~/components/offers/OfferCard/Promo/OfferMainDetailsItem.vue'

@Component({
  components: {
    OfferMainDetailsItem,
    PromoOfferCardDiscountLabel,
    OfferOptions,
    OfferBenefitBadge,
    OfferCardDetailsContent,
    OfferMainDetails,
    OfferCardExclusiveLabel,
    PromoOfferCardLayout,
    ExpertTip,
    OfferCardDetails,
    OfferPromoBanners,
    OfferDynamicBanner,
    OfferCardPrice,
    AlButton,
  },
})
export default class PromoOfferCard extends OfferCardMixin {
  @Prop({ required: true, default: () => ({}), type: Object })
  protected readonly offer!: MobileOffer

  @Prop({ required: false, type: Boolean, default: true })
  clickable!: boolean

  @Prop({
    default: 'hover',
    type: String,
  })
  readonly pricesTooltipTrigger!: TooltipTrigger

  @Prop({ required: false, type: Boolean, default: false })
  topDeal!: boolean

  @Prop({ required: false, type: Boolean, default: false })
  showNewMobileCardsInfo!: boolean

  protected readonly details = createAmpTranslatedOfferDetailsInstance(
    this.offer,
    this.$i18n as TranslatableContext,
  )

  protected readonly benefits = createTranslatedOfferBenefitsInstance(
    this.offer,
    this.$i18n as TranslatableContext,
  )

  protected checkDateLessDays = checkDateLessDays

  get endDateInFewDays () {
    if (!this.offer.endDate) {
      return this.offer.endDate
    }

    return checkDateLessDays(this.offer.endDate) ? this.offer.endDate : null
  }

  get isEuUnlimitedCalls () {
    return (this.offer.dto as any).product.roaming_calls.type === 'unlimited'
  }

  get isEuUnlimitedData () {
    return (this.offer.dto as any).product.roaming_data.type === 'unlimited'
  }

  get offerChData () {
    if ((this.offer.dto as any).product.home_region_data.type === 'unlimited') {
      return this.$t('blackApril.promoCard.unlimited')
    } else {
      return ((this.offer.dto as any).product.home_region_data.amount / 1000) + ' GB'
    }
  }

  get offerChCalls () {
    if ((this.offer.dto as any).product.home_region_calls.type === 'unlimited') {
      return this.$t('blackApril.promoCard.unlimited')
    } else {
      return ((this.offer.dto as any).product.home_region_calls.amount) + ' min.'
    }
  }

  get offerRoamingData () {
    if ((this.offer.dto as any).product.roaming_data.type === 'unlimited') {
      return this.$t('blackApril.promoCard.unlimited')
    } else if ((this.offer.dto as any).product.roaming_data.amount) {
      return ((this.offer.dto as any).product.roaming_data.amount / 1000) + ' GB'
    }

    return '     —   '
  }

  get offerRoamingCalls () {
    if ((this.offer.dto as any).product.home_region_to_roaming_calls.type === 'unlimited') {
      return this.$t('blackApril.promoCard.unlimited')
    } else if ((this.offer.dto as any).product.home_region_to_roaming_calls.amount) {
      return ((this.offer.dto as any).product.home_region_to_roaming_calls.amount) + ' min.'
    } else if ((this.offer.dto as any).product.roaming_calls.type === 'unlimited') {
      return this.$t('blackApril.promoCard.unlimited')
    } else if ((this.offer.dto as any).product.roaming_calls.amount) {
      return ((this.offer.dto as any).product.roaming_calls.amount) + ' min.'
    }

    return '     —   '
  }

  get contractDuration () {
    return (this.offer.dto as any).contract_duration.code
  }

  get voucher ():any {
    return this.details.mainList.filter(item => item.category === 'mostProfitableVoucher')[0]
  }
}
