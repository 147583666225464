var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    _vm.$style['accordion-item'],
    _vm.$style[`accordion-item--${_vm.theme}`],
  ]},[_c('button',{class:[
      _vm.$style['accordion-item-header'],
      {
        [_vm.$style['accordion-item-header--expanded']]: _vm.isExpanded
      }
    ],attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.toggle.apply(null, arguments)}}},[_vm._v("\n    "+_vm._s(_vm.title)+"\n  ")]),_vm._v(" "),_c('div',{class:[
      _vm.$style['accordion-item-content'],
      {
        [_vm.$style['accordion-item-content--expanded']]: _vm.isExpanded
      }
    ]},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }